// Here you can add other styles
.bold {
  font-weight: bold;
}
//estilos HeadCard
.h-card {
  background: #00beb1 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.h-card-matrix {
  background: #E0E0E0 !important;
  color: #1A1A1A !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn-login {
  background: #0169b7;
  height: 45px;
  color: #ffffff;
  &:hover {
    color: #ffffff !important;
  }
}
.btn-add {
  background: #0169b7;
  border: 1px solid #0169b7 !important;
  color: #ffffff;
  &:hover {
    color: #ffffff !important;
  }
}

.btn-back {
  background: transparent;
  border: 1px solid #0169b7 !important;
  color: #0169b7;
}

.f-none {
  &:focus {
    outline: none !important;
  }
}

.c-shadow {
  &:focus {
    box-shadow: 0 0 0 0.1rem #017fdd;
  }
}
.bg-login {
  background: #013259;
}
.btn-logout {
  color: #4f5d73;
  &:hover {
    text-decoration: none;
    color: #4f5d73;
  }
}
.color-layout{
  background: #013259;
  color:#ffffff;
}
.input-group-search {
  .form-control {
    &:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}

.bg-search {
  background-color: #00beb1;

  &.input-group-text {
    padding: 0.3rem 0.75rem;
    cursor: pointer;
  }
}
.jumbotron-view{
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-color: #EEEEEE;
  padding: 1rem 1rem;
}

.page-item {
  &.active {
    .page-link {
      background-color: #00beb1;
      border-color: #00beb1;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.card-statics {
  box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06) !important;
  min-height: 355px !important;
  .title-info-totals {
    h5 {
      @media screen and (min-width: 1200px) and (max-width: 1424px) {
        min-height: 41px;
      }
    }
  }
  .circle-statistics-blue,
  .circle-statistics-yellow,
  .circle-statistics-red,
  .circle-statistics-files {
    border-radius: 50%;
    width: 136px;
    height: 133px;
    font-size: 40px;
    color: #ffff;
    text-align: center;
    padding-top: 35px;
    @media screen and (min-width: 1200px) and (max-width: 1424px) {
      width: 90px;
      height: 95px;
      font-size: 29px;
      color: #ffff;
      padding-top: 24px;
    }
  }
  .circle-statistics-blue {
    background-color: #00c2ed;
  }
  .circle-statistics-yellow {
    background-color: #ffd542;
  }
  .circle-statistics-red {
    background-color: #ff5454;
  }
  .circle-statistics-files {
    background-color: #00d1c3;
  }
}


.item-aleatory {
  padding: 0.375rem 0.75rem;
  background-color: #ced2d8;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}


.c-not-found{
  min-height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}


.colmena {
  // justify-content: end;
  // text-align: end;
  margin-top: -175px;

  @media (max-width: 575.98px) {
    margin-left: 40px;
  }
  @media (min-width: 575.98px) {
    margin-left: 189px;
  }

  @media (min-width: 767.98px) {
    margin-left: 280px;
  }

  @media (min-width: 991.98px) {
    // margin-right: 42px;
    margin-left: 410px;
  }

  @media (min-width: 1199.98px) {
    margin-left: 508px;
  }
}
